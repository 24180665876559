import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import { jwtDecode } from 'jwt-decode';
import './entrar.css';
import logo from './img/log.svg';
import register from './img/register.svg';
import EmailIcon from '@mui/icons-material/Email';
import LockIcon from '@mui/icons-material/Lock';
import PersonIcon from '@mui/icons-material/Person';
import GoogleIcon from '@mui/icons-material/Google';

export default function EntrarCadastro() {
  const [isSignUpMode, setIsSignUpMode] = useState(false);
  const navigate = useNavigate();

  const handleSignUpClick = () => {
    setIsSignUpMode(true);
  };

  const handleSignInClick = () => {
    setIsSignUpMode(false);
  };

  const handleGoogleSuccess = (response) => {
    const user = jwtDecode(response.credential);
    console.log("Usuário autenticado com Google:", user);

    if (!emailValido(user.email)) {
      alert("Email não autorizado!");
    } else {
      navigate('/home');
    }
  };

  const handleGoogleError = () => {
    console.error("Erro durante o login com Google");
  };

  function emailValido(email) {
    const permitidos = ['@academico.ufs.br', 'dcomp.ufs.br'];
    const dominioEmail = email.split('@')[1];
    return permitidos.includes(dominioEmail);
  }

  return (
    <GoogleOAuthProvider clientId="800987484018-3j62a70t8odk985qptapiv9aofsl4fdq.apps.googleusercontent.com">
      <div className={`container ${isSignUpMode ? 'sign-up-mode' : ''}`}>
        <div className="forms-container">
          <div className="signin-signup">
            {/* Formulário de login */}
            <form className="sign-in-form">
              <h2 className="title">Entrar</h2>
              <div className="input-field">
                <i><EmailIcon /></i>
                <input type="email" placeholder="email" name="email" />
              </div>
              <div className="input-field">
                <i><LockIcon /></i>
                <input type="password" placeholder="senha" name="password" />
              </div>
              <button type="submit" className="btn solid">
                Entrar
              </button>
              <p className="social-text">Ou use sua conta Google</p>
              <div className="social-media">
                <GoogleLogin
                  onSuccess={handleGoogleSuccess}
                  onError={handleGoogleError}
                />
              </div>
            </form>
            {/* Formulário de cadastro */}
            <form className="sign-up-form">
              <h2 className="title">Cadastre-se</h2>
              <div className="input-field">
                <i><EmailIcon /></i>
                <input type="email" placeholder="Email" name="email" />
              </div>
              <div className="input-field">
                <i><PersonIcon /></i>
                <input type="text" placeholder="Nome" name="name" />
              </div>
              <div className="input-field">
                <i><LockIcon /></i>
                <input type="password" placeholder="Senha" name="password" />
              </div>
              <input type="submit" className="btn" value="Confirmar" />
              <p className="social-text">Ou cadastre-se com a sua conta Google</p>
              <div className="social-media">
                <GoogleLogin
                  onSuccess={handleGoogleSuccess}
                  onError={handleGoogleError}
                />
              </div>
            </form>
          </div>
        </div>

        <div className="panels-container">
          <div className="panel left-panel">
            <div className="content">
              <h3>Novo por aqui?</h3>
              <p>Crie a sua conta</p>
              <button className="btn transparent" id="sign-up-btn" onClick={handleSignUpClick}>
                Cadastre-se
              </button>
            </div>
            <img src={logo} className="image" alt="" />
          </div>
          <div className="panel right-panel">
            <div className="content">
              <h3>Já tem conta?</h3>
              <p>Acesse a sua conta</p>
              <button className="btn transparent" id="sign-in-btn" onClick={handleSignInClick}>
                Entrar
              </button>
            </div>
            <img src={register} className="image" alt="" />
          </div>
        </div>
      </div>
    </GoogleOAuthProvider>
  );
}
