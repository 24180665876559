import React, { useEffect, useState } from "react";
import axios from 'axios';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  Tooltip,
  XAxis,
  YAxis, ResponsiveContainer
} from "recharts";
import Typography from '@mui/material/Typography';

import { Box, FormControl, InputLabel, MenuItem, Select } from '@mui/material';

const formatAsPercentage = (value) => {
  return `${value}%`;
}

const formatValue = (value) => {
  if (value == 0) {
    return
  }
  return `${value}%`
}

const Chart = ({ data }) => {
  return (
    <ResponsiveContainer width="80%" height={400}>
      <BarChart
        data={data}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 5,
        }}
        barCategoryGap={30} 

      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="periodo" />
        <YAxis tickFormatter={formatAsPercentage} />
        <Tooltip
          itemSorter={(itemA, itemB) => {
            const order = ['Aprovados', 'Cancelados', 'Trancados', 'Reprovados']; 
            const indexA = order.indexOf(itemA?.name);
            const indexB = order.indexOf(itemB?.name);
        
            return indexA - indexB;
          }}
          formatter={(value, name) => {
            console.log(value)
            if (name === 'Aprovados') {
              const aprovadosAbsolutoValue = data.find(item => item.Aprovados === value)?.AprovadosAbsoluto || 0;
              console.log(aprovadosAbsolutoValue)
              return `${aprovadosAbsolutoValue}`;
            } 
            if (name === 'Reprovados') {
              const reprovadosAbsoluto = data.find(item => item.Reprovados === value)?.ReprovadosAbsoluto || 0;
              return `${reprovadosAbsoluto}`;
            } 
            if (name === 'Trancados') {
              const trancadosAbsoluto = data.find(item => item.Trancados === value)?.TrancadosAbsoluto || 0;
              return `${trancadosAbsoluto}`;
            } 
            else{
              const canceladosAbsoluto = data.find(item => item.Cancelados === value)?.CanceladosAbsoluto || 0;
              return `${canceladosAbsoluto}`;

            }
          }}
        />
        <Legend />
        <Bar dataKey="Reprovados" stackId="a" fill="#ff8080" label={{ position: 'right', fill: 'black', formatter: (value) => formatValue(value.toFixed(2)), dataKey: 'Reprovados' }} />
        <Bar dataKey="Trancados" stackId="a" fill="#6ca0dc" label={{ position: 'right', fill: 'black', formatter: (value) => formatValue(value.toFixed(2)), dataKey: 'Trancados' }} />
        <Bar dataKey="Cancelados" stackId="a" fill="orange" label={{ position: 'right', fill: 'black', formatter: (value) => formatValue(value.toFixed(2)), dataKey: 'Cancelados' }} />
        <Bar dataKey="Aprovados" stackId="a" fill="#88d880" label={{ position: 'right', fill: 'black', formatter: (value) => formatValue(value.toFixed(2)), dataKey: 'Aprovados' }} />

      </BarChart>
    </ResponsiveContainer>
  );
};

const App = () => {
  const [data, setData] = useState([]);
  const [curso, setCurso] = useState({ nome: 'cc', valor: 1 });
  const [anoInicio, setAnoInicio] = useState('2018.2');
  const [anoFim, setAnoFim] = useState('2021.2');
  const [materia, setMateria] = useState('COMP0393');
  const [materias, setMaterias] = useState([]);

  useEffect(() => {
    axios.get(`https://backend2-6h3k.onrender.com/api/teste_student/disciplines/disciplinesByPeriod/${curso.valor}`)
      .then(response => {
        console.log(response.data)
        setMaterias(response.data);
      })
      .catch(error => {
        console.error('Erro ao obter lista de matérias da API', error);
      });

  }, [curso]);


  useEffect(() => {
    setData([])
  }, [curso]);


  useEffect(() => {
    console.log(materia)
    axios.get(`https://backend2-6h3k.onrender.com/api/teste_student/grafico1/situation/${curso.valor}/${anoInicio}/${anoFim}/${materia}/`)
      .then(response => {
        setData(response.data);
      })
      .catch(error => {
        console.error('Erro ao obter dados da API', error);
      });

    console.log(data)
  }, [anoInicio, anoFim, curso, materia]);

  const anos = ['2018.2', '2019.1', '2019.2', '2020.1', '2020.2', '2021.1', '2021.2'];
  const cursos = [{ nome: 'cc', valor: 1 }, { nome: 'ec', valor: 2 }, { nome: 'si', valor: 3 }];

  return (
    <div>
      <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
      <FormControl sx={{ m: 1, minWidth: 120 }}>
          <InputLabel id="">Curso</InputLabel>
          <Select
            value={curso}
            onChange={e => setCurso(e.target.value)}
            label="Curso"
            renderValue={(selected) => selected.nome}
          >
            {cursos.map((item, i) => (
              <MenuItem key={i} value={item}>{item.nome}</MenuItem>
            ))}
          </Select>

        </FormControl>
        <FormControl sx={{ m: 1, minWidth: 120 }}>
          <InputLabel id="">Início</InputLabel>
          <Select value={anoInicio} onChange={e => setAnoInicio(e.target.value)} label="Início">
            {anos.map((item, i) => (
              <MenuItem key={i} value={item}>{item}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl sx={{ m: 1, minWidth: 120 }}>
          <InputLabel id="">Fim</InputLabel>
          <Select value={anoFim} onChange={e => setAnoFim(e.target.value)} label="Fim">
            {anos
              .filter(item => item >= anoInicio)
              .map((item, i) => (
                <MenuItem key={i} value={item}>{item}</MenuItem>
              ))}
          </Select>
        </FormControl>
        <FormControl sx={{ m: 1, minWidth: 120 }}>
          <InputLabel id="">Matéria</InputLabel>
          <Select value={materia} onChange={e => setMateria(e.target.value)} label="Matéria">
            {materias.map((item, i) => (
              <MenuItem key={i} value={item.code}>{item.name}</MenuItem>
            ))}
          </Select>

        </FormControl>
      </Box>

      <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
        <Typography variant="h6">{`Relação de aprovados, trancados e cancelados`}{anoInicio ? `: ${anoInicio}` : ''}{anoFim ? ` - ${anoFim}` : ''}{curso ? ` em ${curso.nome}` : ''}</Typography>
      </Box>

      <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
        <Chart data={data} />
      </Box>
    </div>
  );
};

export default App;
