import Box from '@mui/material/Box';
import React, { useEffect, useState } from "react";
import axios from 'axios';
import { DataGrid, ptBR } from '@mui/x-data-grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { Grid } from "@mui/material";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from "recharts";
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';

export default function Grafico() {
  const token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYW1lIjoiam9zZSBkaG9uYXRhcyIsImlhdCI6MTcwOTA4MDE4NiwiZXhwIjoxNzA5MTY2NTg2fQ.fGIFRA18XAcW3IbKQ2fsiG-BQOh1uoYIePgR4TX86dg"
  const [dataGridKey, setDataGridKey] = useState(0);
  const graficos = ['APROVADO', 'REPROVADO', 'DISPENSADO', 'TRANCADO'];
  const anos = ['2018.2', '2019.1', '2019.2', '2020.1', '2020.2', '2021.1', '2021.2'];
  const cursos = [{ nome: 'cc', valor: 1 }, { nome: 'ec', valor: 2 }, { nome: 'si', valor: 3 }];
  const [curso, setCurso] = useState({ nome: 'cc', valor: 1 });
  const [grafico, setTipoGrafico] = useState('APROVADO');
  const [anoInicio, setSelects2] = useState('2018.2');
  const [anoFim, setSelects3] = useState('2021.2');
  const [materiasSelecionadas, setSelectedSubjects] = useState([]);
  const [data, setData] = useState([]);
  const [materias, setMaterias] = useState([1])
  const [materias2, setMaterias2] = useState([1])
  const [primeiroGet, setPrimerioGet] = useState(true);
  const [dataMaterias, setDataMaterias] = useState([])


  useEffect(() => {

    const getToken = async () => {
      try {
        const temp_token = await axios.get('https://backend2-6h3k.onrender.com/api/teste_student/user', {
          params: {
            "email": "dhonatas@academico.ufs.br",
            "password": "vaporeon"
          }
        })
        console.log(temp_token.data)
      } catch (error) {
        console.error('Erro ao obter dados da API:', error);
      }
    }
    const fetchDisciplines = async () => {
      try {
        const response = await axios.get(`https://backend2-6h3k.onrender.com/api/teste_student/disciplines/disciplinesByPeriod/${curso.valor}`, {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });
        const allData = response.data;

        const limitedData = allData

        setDataMaterias(limitedData)
      } catch (error) {
        console.error('Erro ao obter dados da API:', error);
      }
    };

    //getToken()
    fetchDisciplines();
  }, []);


  useEffect(() => {
    const fetchDisciplines = async () => {
      const stringFormatada = materias.join(',');

      try {
        const response = await axios.get(`https://backend2-6h3k.onrender.com/api/teste_student/grafico1/${curso.valor}/${anoInicio}/${anoFim}/${stringFormatada}`, {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });
        const allData = response.data;
        console.log("oiiii")
        console.log(allData)

        const aprovadosData = allData.filter(item => item.situation === grafico);

        const modifiedData = aprovadosData.map(item => ({
          periodo: item.period,
          [item.nome_disciplina]: item.quantidade,
        }));

        setSelectedSubjects(aprovadosData);
        //console.log("oi")
        console.log(aprovadosData)

        const resultado = modifiedData.reduce((agregado, atual) => {
          const periodoExistente = agregado.find(item => item.periodo === atual.periodo);
        
          if (periodoExistente) {
            Object.keys(atual).forEach(disciplina => {
              if (disciplina !== 'periodo') {
                periodoExistente[disciplina] = (periodoExistente[disciplina] || 0) + atual[disciplina];
              }
            });
          } else {
            agregado.push({ ...atual });
          }
        
          return agregado;
        }, []);

        setData(resultado);

      } catch (error) {
        console.error('Erro ao obter dados da API:', error);
      }
    };

    fetchDisciplines();
  }, [anoInicio, anoFim, curso, materias, grafico]);


  const Chart = () => {
    const chartData2 = data;
    const teste = materiasSelecionadas.filter((disciplina, index, self) =>
      index === self.findIndex((d) => d.nome_disciplina === disciplina.nome_disciplina)
    );

    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <LineChart width={750} height={400} data={chartData2}>
          <XAxis dataKey="periodo" label={{ value: "Período", position: "insideBottom", angle: 0, dy: 10 }} />
          <YAxis label={{ value: `${grafico}`, angle: -90, dx: -20 }} />
          <CartesianGrid strokeDasharray="3 3" />
          <Tooltip />
          <Legend />

          {teste.map((subject, index) => (
            <Line
              connectNulls
              key={index}
              type="monotone"
              isAnimationActive={false}
              dataKey={subject.nome_disciplina}
              stroke={getRandomColor()}

              />
          ))}
        </LineChart>

      </div>
    );
  };


  const getRandomColor = () => {
    const getRandomDarkByte = () => Math.floor(Math.random() * 200); 
    const darkColor = `rgb(${getRandomDarkByte()}, ${getRandomDarkByte()}, ${getRandomDarkByte()})`;
    return darkColor;
  };
  useEffect(() => {
    setDataGridKey((prevKey) => prevKey + 1);
    //setSelectedSubjects([{ cor: "", data: [], intervalo: [anoInicio !== null ? anoInicio.toString() : "2018.2", anoFim !== null ? anoFim : "2019.2"], nome: "" }]);
    //setMaterias([]);
    //setPrimerioGet(false)

  }, [materias]);


  const handleSelectionModelChange = (ids) => {
    const selectedIDs = new Set(ids);
    const selectedRows = rows.filter((row) => selectedIDs.has(row.id));
    const selectedCodes = selectedRows.map((row) => row.code);

    setMaterias(selectedCodes);
    setMaterias2(ids)

    if (selectedRows.length === 0) {
      setSelectedSubjects([])
      setMaterias([])

    } else {
      setPrimerioGet(false);
      setMaterias(selectedCodes);
      setMaterias2(ids)


      console.log(materiasSelecionadas)
      const materiasSelecionadasCopy = [...materiasSelecionadas];
      const materiasSelecionadasUpdated = selectedRows.map((row) => {
        const materiaSelecionada = materiasSelecionadasCopy.find((subject) => subject.nome === row.nome);
        return {
          name: row.name,
        };
      });

      setSelectedSubjects(materiasSelecionadasUpdated);
    }
  };

  const columns = [
    { field: 'name', headerName: 'Nome', width: 200 },
    {
      field: 'period', headerName: 'Período', width: 200, headerAlign: 'center',
      align: 'center'
    },
  ];

  const rows = dataMaterias.map((item) => ({ ...item }));

  return (
    <div>
      <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
        <FormControl sx={{ m: 1, minWidth: 120 }}>
          <InputLabel id="">Grafico</InputLabel>
          <Select value={grafico} onChange={e => setTipoGrafico(e.target.value)} label="Grafico">
            {graficos.map((item, i) => (
              <MenuItem value={item}>{item}</MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl sx={{ m: 1, minWidth: 120 }}>
          <InputLabel id="">Curso</InputLabel>
          <Select
            value={curso}
            onChange={e => setCurso(e.target.value)}
            label="Curso"
            renderValue={(selected) => selected.nome}
          >
            {cursos.map((item, i) => (
              <MenuItem key={i} value={item}>{item.nome}</MenuItem>
            ))}
          </Select>

        </FormControl>
        <FormControl sx={{ m: 1, minWidth: 120 }}>
          <InputLabel id="">Início</InputLabel>
          <Select value={anoInicio} onChange={e => setSelects2(e.target.value)} label="Início"
          >
            {
              anos.map((item, i) => (
                <MenuItem key={i} value={item}>{item}</MenuItem>
              ))
            }

          </Select>
        </FormControl>
        <FormControl sx={{ m: 1, minWidth: 120 }}>
          <InputLabel id="">Fim</InputLabel>
          <Select value={anoFim} onChange={e => setSelects3((e.target.value))} label="Fim"
          >
            {anos
              .filter(item => item >= anoInicio)
              .map((item, i) => (
                <MenuItem key={i} value={item}>{item}</MenuItem>
              ))}
          </Select>
        </FormControl>
      </Box>

      <Grid container justifyContent="center" >
        <Grid item xs={12} sm={12} md={7} lg={7}>
          <Typography variant="h6" style={{ textAlign: 'center' }}>{grafico ? `Relação dos ${grafico}` : ''}{anoInicio ? `: ${anoInicio}` : ''}{anoFim ? ` - ${anoFim}` : ''}{curso ? ` em ${curso.nome}` : ''}</Typography>
          <Chart></Chart>
        </Grid>
        <Grid item xs={12} sm={12} md={5} lg={5}>
          <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '40px' }}>
            <div style={{ height: 400, width: '80%' }}>
              <DataGrid
                key={dataGridKey}
                localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
                checkboxSelection
                selectionModel={data.length > 0 && primeiroGet ? handleSelectionModelChange([44]) : materias2}
                onSelectionModelChange={handleSelectionModelChange}
                rows={rows}
                columns={columns}
                hideFooterPagination
              />
            </div>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
}